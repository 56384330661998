document.addEventListener('turbo:load', function() {
  if (!document.getElementById('edit-job-page')) return;

  const formLinkContainer = document.getElementById('contractor-form-link');
  const copyButton = formLinkContainer.getElementsByClassName('copy-url-button')[0];
  const urlLink = formLinkContainer.getElementsByTagName('a')[0];
  const successCheck = formLinkContainer.getElementsByClassName('success-check')[0];
  copyButton.addEventListener('click', () => {
    navigator.clipboard.writeText(urlLink.href)
      .then(() => {
        successCheck.classList.remove('hidden');
        copyButton.classList.add('hidden');
        setTimeout(() => {
          successCheck.classList.add('hidden');
          copyButton.classList.remove('hidden');
        }, 600)
      });
  });
});